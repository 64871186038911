import { ContentWrapper } from "../../../../Pages/style";
import Markdown from "../../Markdown";

const RichText = ({ attr }) => {
  return (
    <ContentWrapper>
      <Markdown content={attr.Content}></Markdown>
    </ContentWrapper>
  );
};

export default RichText;
