import styled from "styled-components";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ButtonWrapper = styled.div`
  display: none;
`;
const getButtonPadding = (size) => {
  switch (size?.toLowerCase()) {
    case "small":
      return "4px";
    case "normal":
      return "16px";
    case "large":
      return "24px 34px";
  }
  return "24px 34px";
};
const getButtonBorderRadius = (size) => {
  switch (size?.toLowerCase()) {
    case "small":
      return "2px";
    case "normal":
      return "12px";
    case "large":
      return "16px";
  }
  return "16px";
};
const getButtonFontSize = (size) => {
  switch (size?.toLowerCase()) {
    case "small":
      return "16px";
    case "normal":
      return "16px";
    case "large":
      return "16px";
  }
  return "16px";
};
const getButtonsGroupWrapperJustify = (align) => {
  switch (align?.toLowerCase()) {
    case "leading":
      return "flex-start";
    case "trailing":
      return "flex-end";
    case "centered":
      return "center";
    default:
      return "flex-start";
  }
};

const ButtonBase = styled(Button)`
  border-radius: ${(props) => getButtonBorderRadius(props.size)} !important;

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: ${(props) => getButtonFontSize(props.size)} !important;
  line-height: normal !important;
  text-align: center !important;
  box-shadow: none !important;
  text-transform: none !important;
  margin: 0px !important;

  padding: ${(props) => getButtonPadding(props.size)} !important;

  @media screen and (max-width: 899px) {
    padding: 12px !important;
  }

  @media screen and (max-width: 346px) {
    margin-bottom: 10px !important;
  }
`;

export const LinkBase = styled(Link)`
  border-radius: ${(props) => getButtonBorderRadius(props.size)} !important;

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: ${(props) => getButtonFontSize(props.size)} !important;
  line-height: normal !important;
  text-align: center !important;
  box-shadow: none !important;
  text-transform: none !important;
  margin: 0px !important;
  text-decoration: none;

  padding: ${(props) => getButtonPadding(props.size)} !important;

  @media screen and (max-width: 899px) {
    padding: 12px !important;
  }

  @media screen and (max-width: 346px) {
    margin-bottom: 10px !important;
  }
`;
export const ButtonPrimary = styled(ButtonBase)`
  background-color: rgba(255, 147, 30, 1) !important;
  color: #fff !important;
`;
export const LinkPrimary = styled(LinkBase)`
  background-color: rgba(255, 147, 30, 1) !important;
  color: #fff !important;
`;
export const ButtonSecondary = styled(ButtonBase)`
  background-color: rgba(252, 238, 221, 1) !important;
  color: rgba(255, 147, 30, 1) !important;
`;
export const LinkSecondary = styled(LinkBase)`
  background-color: rgba(252, 238, 221, 1) !important;
  color: rgba(255, 147, 30, 1) !important;
`;
export const ButtonTertiary = styled(ButtonBase)`
  background-color: rgba(162, 162, 163, 0.08) !important;
  color: #4d4d4d !important;
`;
export const LinkTertiary = styled(LinkBase)`
  background-color: rgba(162, 162, 163, 0.08) !important;
  color: #4d4d4d !important;
`;
export const ButtonLink = styled(ButtonBase)`
  background-color: transparent !important;
  color: rgba(255, 147, 30, 1) !important;
`;
export const LinkLink = styled(LinkBase)`
  background-color: transparent !important;
  color: rgba(255, 147, 30, 1) !important;
`;
export const ButtonClear = styled(ButtonBase)`
  background-color: transparent !important;
  color: rgba(77, 77, 77, 1) !important;
`;
export const LinkClear = styled(LinkBase)`
  background-color: transparent !important;
  color: rgba(77, 77, 77, 1) !important;
`;
export const ButtonsGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => getButtonsGroupWrapperJustify(props.Align)};

  width: 100%;
  gap: 24px;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;
