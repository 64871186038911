import React from "react";
import {
  JobPostFilterWrap,
  JobPostLink,
  JobPostTab,
  JobPostTabLabelWrap,
  JobPostTabs,
  Wrapper,
  JobItem,
} from "./style";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import JobPostDetail from "../JobPostDetail";
import TabPanel from "../TabPanel";
import { CustomiseCard } from "../style";
import { CustomDropDown, DropDownLabel } from "../JobOpportunity/style";
import { SelectIcon } from "../../Icons/SelectIcon";
import { JobPostWrap, JobTitle } from "../JobsList/style";
import { Link } from "react-router-dom";

const qs = require("qs");

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const JobPostDetails = () => {
  const [OpportunitiesItems, setOpportunitiesItems] = React.useState([]);

  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [sortBy, setSortBy] = React.useState(1);

  const handleSortByChange = (event) => {
    getJobs(null, event.target.value);
    setSortBy(event.target.value);
  };
  React.useEffect(() => {
    getJobs(null, 1);
  }, []);
  const getJobs = (event, sort) => {
    let sortField = "Date";
    let sortOrder = "desc";
    if ((sort ?? sortBy) == 1) {
      sortField = "Date";
      sortOrder = "desc";
    } else if ((sort ?? sortBy) == 2) {
      sortField = "Date";
      sortOrder = "asc";
    }
    let args = {
      sort: [`${sortField}:${sortOrder}`],
      populate: "*",
      pagination: {
        page: 1,
        pageSize: 5,
      },
    };

    const query = qs.stringify(
      args,

      {
        encodeValuesOnly: true, // prettify URL
      }
    );

    fetch(`${process.env.REACT_APP_CMSURL}/api/job-opportunities?${query}`)
      .then((response) => response.json())
      .then((response) => {
        setOpportunitiesItems(response?.data ?? []);
      });
  };
  return (
    <Wrapper>
      <Grid container columns={{ xs: 1, sm: 6, md: 12 }} spacing={2}>
        <Grid item xs={1} sm={6} md={4}>
          <div>
            <CustomiseCard padding="0 0 24px 0">
              <JobPostFilterWrap>
                <JobPostLink href="/career" underline="none">
                  <img src="/images/LeftArrow.svg" alt="not-found" />
                  {t("careerDetails.jobPostDetails.filter.title")}
                </JobPostLink>
                <div>
                  <DropDownLabel>
                    {t("careerDetails.jobPostDetails.filter.sorting.title")}
                  </DropDownLabel>
                  <CustomDropDown
                    value={sortBy}
                    onChange={handleSortByChange}
                    IconComponent={SelectIcon}
                  >
                    <MenuItem value={1}>
                      {t("careerDetails.jobPostDetails.filter.sorting.option1")}
                    </MenuItem>
                    <MenuItem value={2}>
                      {t("careerDetails.jobPostDetails.filter.sorting.option2")}
                    </MenuItem>
                  </CustomDropDown>
                </div>
              </JobPostFilterWrap>
              <JobPostTabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Faq Tabs"
                TabIndicatorProps={{
                  style: {
                    background: "#FF931E",
                    borderRadius: "0px 0px 0px 0px",
                    width: "0px",
                    left: "0",
                  },
                }}
              >
                {OpportunitiesItems.map((item) => {
                  return (
                    <JobItem href={`/career/${item.documentId}`}>
                      <JobPostTab
                        label={
                          <>
                            <JobPostTabLabelWrap>
                              <JobTitle>{item.Title}</JobTitle>
                              <JobPostWrap style={{ margin: "8px 0" }}>
                                <img
                                  src="/images/LocationLiner.svg"
                                  alt="not-found"
                                />
                                <span>{item.Location.Location}</span>
                              </JobPostWrap>
                              <JobPostWrap style={{ marginBottom: "8px" }}>
                                <img
                                  src="/images/TimeLiner.svg"
                                  alt="not-found"
                                />
                                <span>{item.Date}</span>
                              </JobPostWrap>
                              <JobPostWrap style={{ marginBottom: "8px" }}>
                                <img
                                  src="/images/ContentLiner.svg"
                                  alt="not-found"
                                />
                                <span>{item.JobCategory}</span>
                              </JobPostWrap>
                            </JobPostTabLabelWrap>
                          </>
                        }
                        {...a11yProps(0)}
                      />
                    </JobItem>
                  );
                })}
                ;
              </JobPostTabs>
            </CustomiseCard>
          </div>
        </Grid>
        <Grid item xs={1} sm={6} md={8}>
          <div>
            <TabPanel value={value} index={0}>
              <JobPostDetail />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default JobPostDetails;
