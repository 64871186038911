import * as React from "react";
import { ContentWrapper } from "../../../../Pages/style";
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionItem,
  CustomAccordionItemIcon,
  CustomAccordionItemDetails,
  CustomAccordionItemDetailsTitle,
  CustomAccordionLabel,
  CustomAccordionSummary,
  CustomAccordionWrapperDetails,
} from "./styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect } from "react";

const Accordion = ({ items, isDropDown }) => {
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    if (isDropDown == false) {
      setExpanded(true);
    }
  });
  const handleChange = () => (event, isExpanded) => {
    if (isDropDown == false) {
    } else {
      setExpanded(isExpanded);
    }
  };

  return (
    <div>
      <CustomAccordion expanded={expanded} onChange={handleChange()}>
        <CustomAccordionSummary isDropDown={isDropDown}>
          <CustomAccordionLabel>
            <span>{items[0]?.Title}</span>
            {isDropDown ? (
              <img
                className={expanded ? "expanded" : ""}
                src="/images/MinimizeIcon.svg"
                alt="MinimizeIcon"
              />
            ) : (
              <></>
            )}
          </CustomAccordionLabel>
        </CustomAccordionSummary>
        <CustomAccordionWrapperDetails>
          <CustomAccordionDetails>
            {items.slice(1).map((item) => {
              return (
                <CustomAccordionItem>
                  <CustomAccordionItemIcon>
                    {item.Icon ? (
                      <img
                        alt={item.Icon.alternativeText}
                        src={`${process.env.REACT_APP_CMSURL}${item.Icon.url}`}
                      />
                    ) : (
                      []
                    )}
                  </CustomAccordionItemIcon>
                  <CustomAccordionItemDetails>
                    <CustomAccordionItemDetailsTitle>
                      {item.Title}
                    </CustomAccordionItemDetailsTitle>
                  </CustomAccordionItemDetails>
                </CustomAccordionItem>
              );
            })}
          </CustomAccordionDetails>
        </CustomAccordionWrapperDetails>
      </CustomAccordion>
    </div>
  );
};

export default Accordion;
