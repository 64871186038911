import * as React from "react";
import { useState, useEffect } from "react";
import MobileNav from "../MobileNav";
import {
  LoginBtn,
  Logo,
  MenuBar,
  MenuItem,
  PrimaryNav,
  LangSelectorContainer,
  ActionsContainer,
} from "./style";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PopperDropdown from "../PoperDropdown";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Navbar({ items }) {
  const [isLargeScreen, setIsLargeScreen] = useState(Boolean);
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("en");
  const { i18n, t } = useTranslation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const languageSelectHandler = (event) => {
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value).then(() => {
      document.body.dir = i18n.dir();
    });
  };

  const handleResize = () => {
    if (window.innerWidth < 980) {
      setIsLargeScreen(false);
    } else {
      setIsLargeScreen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);
  return (
    <>
      <PrimaryNav>
        <MenuItem to={"/"}>
          <Logo src="/images/Logo.svg" />
        </MenuItem>

        {!isLargeScreen ? (
          <>
            <MenuItem to="#!" onClick={handleOpen}>
              <Logo src="/images/nav-icon.svg" className="nav-icon" />
            </MenuItem>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <MobileNav
                  onClose={handleClose}
                  pages={items}
                  language={language}
                  onSelect={languageSelectHandler}
                />
              </Box>
            </Modal>
          </>
        ) : (
          <>
            <MenuBar>
              {items.map((page) => (
                <MenuItem to={page.Url} key={page.Title}>
                  {page.Title}
                </MenuItem>
              ))}
              {/* Todo: prevent widening of navbar while choosing other language in footer - wasn't able to reproduce */}
            </MenuBar>
            <ActionsContainer>
              <LangSelectorContainer>
                {
                  <LanguageSelector
                    defaultLanguage={language}
                    onSelect={languageSelectHandler}
                    language={language}
                  />
                }
              </LangSelectorContainer>

              <Link
                to={"https://platform.digidoe.com/"}
                className={"mailLink"}
                target={"_blank"}
              >
                <LoginBtn>{t("navbar.login")}</LoginBtn>
              </Link>
            </ActionsContainer>
          </>
        )}
      </PrimaryNav>
    </>
  );
}

export default Navbar;
