import React from "react";
import { Grid } from "@mui/material";
import { Logo } from "../Navbar/style";
import {
  Container,
  FooterContent,
  SocialWrapper,
  Wrapper,
  GridDiv,
  GridWrapper,
  LogoImg,
  LogoBlocks,
} from "./style.js";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageSelector from "../Navbar/LanguageSelector";
import { useState } from "react";

const Footer = ({ items }) => {
  const { i18n, t } = useTranslation();
  const [location, setLocation] = useState("en");

  const locationSelectHandler = (event) => {
    setLocation(event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  return (
    <Container langDirection={i18n.dir()}>
      <GridDiv>
        <GridWrapper>
          <Logo src="/images/Logo.svg" />
          <SocialWrapper>
            <Link to={"https://www.linkedin.com/company/digidoe"}>
              <img src="/images/linkedin.svg" alt="linkedin-logo" />
            </Link>

            <Link
              to={
                "https://apps.apple.com/gb/app/digidoe-finance-solutions/id6443841487"
              }
              target="_blank"
              className={"link"}
            >
              <LogoImg src="/images/AppStoreLogo.svg" alt="news-single" />
            </Link>

            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.digidoe.businessbanking&pli=1"
              }
              target="_blank"
              className={"link"}
            >
              <LogoImg src="/images/GooglePlayLogo.svg" alt="news-single" />
            </Link>

            <Grid container xs={12} sm={3} md={3} lg={3}>
              <Link to={"mailto: info@digidoe.com"} className={"mailLink"}>
                <Wrapper>
                  <img src="/images/sms.svg" alt="email-logo" />
                  <p>{t("footer.email")}</p>
                </Wrapper>
              </Link>
            </Grid>

            {/*<Grid container xs={12} sm={12} md={6}>*/}
            {/*  <Wrapper>*/}
            {/*    <p>*/}
            {/*      {" "}*/}
            {/*      {<LanguageSelector defaultLanguage={location} onSelect={locationSelectHandler} location={location} />}*/}
            {/*    </p>*/}
            {/*  </Wrapper>*/}
            {/*</Grid>*/}
          </SocialWrapper>

          <FooterContent>
            <p>{t("footer.content")}</p>
            <p>{t("footer.info")}</p>
            <p>© DigiDoe {new Date().getFullYear()}. All rights reserved.</p>
          </FooterContent>
        </GridWrapper>
        {items.map((column) => (
          <GridWrapper>
            <ul>
              <Link className={"link"}>
                <b>{column.Label}</b>
              </Link>
              {column.Items.map((page) => (
                <li>
                  <a href={page.Url} className="link">
                    {page.Title}
                  </a>
                </li>
              ))}
            </ul>
          </GridWrapper>
        ))}
      </GridDiv>
    </Container>
  );
};

export default Footer;
