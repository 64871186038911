import {
  TextWithImageWrapper,
  TextWithImageWrapperReverse,
  TextWithImageWrapperContent,
  TextWithImageWrapperTitle,
  TextWithImageWrapperDescription,
  TextWithImageWrapperActions,
  TextWithImageWrapperImage,
  TextWithImageWrapperTop,
  TextWithImageWrapperTopReverse,
} from "./styles";
import { ContentWrapper } from "../../../../Pages/style";
import StrapiButton from "../Buttons/Button";
import StrapiAccordion from "./Accordion";
import Markdown from "../../Markdown";
import BlockStep from "./BlockStep";

const TextWithImage = ({ attr, emit, steps }) => {
  let ContextWrapper = TextWithImageWrapper;
  let direction = "ltr";
  if (attr.Direction == "Content – Image Vertical") {
    ContextWrapper = TextWithImageWrapperTop;
    direction = "ttb";
  } else if (attr.Direction == "Image – Content") {
    ContextWrapper = TextWithImageWrapperReverse;
    direction = "rtl";
  } else if (attr.Direction == "Image – Content Vertical") {
    ContextWrapper = TextWithImageWrapperTopReverse;
    direction = "btt";
  }

  const hideTitle = attr.HideTitle && attr.HideTitle == true ? true : false;
  const stepIndex = (steps ?? []).findIndex(({ BlockId }) => {
    if (attr.BlockId) {
      return BlockId == attr.BlockId;
    }
    return false;
  });

  return (
    <ContentWrapper id={attr.BlockId ?? ""}>
      <ContextWrapper>
        <TextWithImageWrapperContent direction={direction}>
          {stepIndex >= 0 ? <BlockStep attr={steps[stepIndex]} /> : []}
          {hideTitle ? (
            []
          ) : (
            <TextWithImageWrapperTitle>{attr.Title}</TextWithImageWrapperTitle>
          )}
          {attr.Content ? (
            <TextWithImageWrapperDescription>
              <Markdown content={attr.Content} />
            </TextWithImageWrapperDescription>
          ) : (
            []
          )}
          {attr.Accordion && attr.Accordion.length ? (
            <StrapiAccordion
              items={attr.Accordion}
              isDropDown={attr.AccordionIsDropDown}
            />
          ) : (
            []
          )}
          {attr.Actions && attr.Actions.length ? (
            <TextWithImageWrapperActions>
              {attr.Actions.map((action) => {
                return <StrapiButton attr={action} onClick={emit} />;
              })}
            </TextWithImageWrapperActions>
          ) : (
            []
          )}
        </TextWithImageWrapperContent>
        <TextWithImageWrapperImage>
          {attr.Image ? (
            <img
              alt={attr.Image.alternativeText}
              src={`${process.env.REACT_APP_CMSURL}${attr.Image.url}`}
            />
          ) : (
            []
          )}
        </TextWithImageWrapperImage>
      </ContextWrapper>
    </ContentWrapper>
  );
};

export default TextWithImage;
