import { css } from "@emotion/react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary } from "@mui/material";

export const TextWithImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
  justify-content: stretch;
  padding: 48px 0px 48px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
export const TextWithImageWrapperReverse = styled(TextWithImageWrapper)`
  flex-direction: row-reverse;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;
export const TextWithImageWrapperTop = styled(TextWithImageWrapper)`
  flex-direction: column;
`;
export const TextWithImageWrapperTopReverse = styled(TextWithImageWrapper)`
  flex-direction: column-reverse;
`;

const textWithImageContentAlign = (direction) => {
  switch (direction) {
    case "ttb":
    case "btt":
      return "center";
      break;
    default:
      return "flex-start";
      break;
  }
  return "flex-start";
};
const textWithImageContentJustify = (direction) => {
  switch (direction) {
    case "ttb":
    case "btt":
      return "center";
      break;
    default:
      return "stretch";
      break;
  }
  return "stretch";
};
export const TextWithImageWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => textWithImageContentAlign(props.direction)};
  justify-content: ${(props) => textWithImageContentJustify(props.direction)};
  width: 100%;
  gap: 12px;
`;
export const TextWithImageWrapperTitle = styled.div`
  font-size: 40px;
  font-weight: 700;
  line-height: 47.73px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
  color: #000;
`;
export const TextWithImageWrapperDescription = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.5px;
  text-align: left;
  color: rgba(77, 77, 77, 1);
`;
export const TextWithImageWrapperActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TextWithImageWrapperImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    height: auto;
    object-fit: contains;
  }
`;
export const GridWrapper = styled.div`
  padding: 24px 0px;
`;
export const GridCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;
  padding: 36px;
  position: relative;
  z-index: 0;
  height: 264px;
  &:before {
    content: "";
    border-radius: 24px;
    background: rgba(241, 243, 245, 1);
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) top,
      0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) right,
      0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) bottom,
      0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) left,
      0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) box-shadow,
      0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) background-color;
  }
  span {
    z-index: 2;

    font-size: 24px;
    font-weight: 500;
    line-height: 28.64px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(56, 66, 84, 1);
  }
  &:hover {
    &:before {
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
    }
    & > div {
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
    }
  }
`;

export const GridCardInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 24px;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) top,
    0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) right,
    0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) bottom,
    0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) left,
    0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) box-shadow,
    0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) background-color;
`;
export const CustomAccordion = styled(Accordion)`
  border-radius: 24px !important;
  margin-bottom: 16px !important;
  box-shadow: none !important;
  padding: 0px !important;
  cursor: auto;

  &::before {
    background-color: transparent !important;
  }
`;
export const CustomAccordionDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  gap: 8px;
  cursor: auto;
`;
export const CustomAccordionItem = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 32px 1fr;
  grid-gap: 12px;
  padding: 12px 16px;
  background: rgba(248, 248, 248, 1);
  border-radius: 16px;
`;
export const CustomAccordionItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;
export const CustomAccordionItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
export const CustomAccordionItemDetailsTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: rgba(18, 18, 18, 1);
  min-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
export const CustomAccordionLabel = styled.div`
  color: rgba(255, 147, 30, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  span {
  }
  img {
    &.expanded {
      transform: rotate(180deg);
    }
  }
`;
export const CustomAccordionSummary = styled(AccordionSummary)`
  cursor: ${(props) => (props.isDropDown ? "auto" : "default !important")};
  padding: 0px !important;
`;
export const CustomAccordionWrapperDetails = styled(AccordionSummary)`
  padding: 0px !important;
  cursor: auto;
  & > div {
    margin: 0px !important;
  }
`;

export const StepsContainerWrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 0px 0px;
  z-index: 3;
  &.stacked {
    & > div {
      position: fixed;
      top: inherit;
      left: 0px;
      right: 0px;
      background-color: #fff;
    }
  }
`;
export const StepsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  align-items: flex-start;
  padding: 16px 0px;
  background-color: #fff;
`;
export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  position: relative;
  z-index: 0;
  &:not(:last-child) {
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      display: block;
      top: calc(60px + 12px + 4px + (32px / 2) - 2px);
      height: 4px;
      background: rgba(241, 241, 241, 1);
      left: 50%;
      right: 0px;
    }
  }
  &:not(:first-child) {
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      display: block;
      top: calc(60px + 12px + 4px + (32px / 2) - 2px);
      height: 4px;
      background: rgba(241, 241, 241, 1);
      left: 0px;
      right: 50%;
    }
  }
`;
export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  &:hover {
    .step {
      background: rgba(255, 147, 30, 1);
      color: #fff;
    }
  }
`;
export const StepIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
  box-sizing: content-box;
`;
export const StepNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: rgba(241, 241, 241, 1);

  font-size: 16px;
  font-weight: 700;
  line-height: 19.09px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: rgba(56, 66, 84, 1);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StepDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;
export const StepDetailsTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19.09px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: rgba(18, 18, 18, 1);
  max-width: 140px;
`;
export const StepDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: rgba(143, 154, 168, 1);
  gap: 8px;
  span {
  }
`;
export const StepDetailsWrapperIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BlockStepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
`;
export const BlockStepNumber = styled.div`
  text-transform: uppercase;
  background: rgba(255, 147, 30, 0.08);
  padding: 8px 16px;
  color: rgba(255, 147, 30, 1);

  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: center;
  border-radius: 100px;
`;
export const BlockStepDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: left;
  color: rgba(143, 154, 168, 1);
  margin-top: 8px;
`;
